
import { error } from '@exp/exp-utils/helper/logger';
import { merge as objectMerge } from '@exp/exp-utils/helper/object';
import { getISODate } from '@exp/exp-utils/helper/date';
import { getClickEventData as getBrowserClickEventData } from '@exp/exp-utils/helper/browser';
import SchemaHandler from '@exp/exp-utils/schema/schemaHandler';

import {
  getEventProperties,
  setClientSessionIdentifiers,
  addDefaultEventProperties } from '../../../../shared/src/traffic/eventProperties';
import { sendEvent } from '../../../../shared/src/traffic/eventSend';

import page from '../../../../shared/src/traffic/eventPageProperties';
import TData from '../../../../shared/src/helpers/tdata';


class AddEventHandler extends SchemaHandler {
  preProcess() {
    setClientSessionIdentifiers(page);
    this.pageEvent = getEventProperties();
    this.tData = new TData();
    this._buildEvent();

    // Convert EID to lower case characters
    this.pageEvent.set('e_id', this.pageEvent.get('e_id').toLowerCase());
  }

  process(sinkFnMap) {
    // Don't process event if event contains invalid characters
    if (!this._validateEIDCharacters(this.pageEvent.get('e_id'))) return;

    // Validate EID formating after ensuring event is not going to be dropped.
    this._validateEIDFormat(this.pageEvent.get('e_id'));

    super.process(
      objectMerge({
        EVENT_SVC: () => { this._handleEventSvc(); }
      }, sinkFnMap));
  }

  _handleEventSvc() {
    sendEvent(this.pageEvent.getProperties(), '/pageEvents.aspx', 'GET');
  }

  _validateEIDFormat(eId) {
    const validEID = /^([a-z0-9_\-]+)\.([a-z0-9_\-]+)\.(([a-z0-9_\/\-]+)\.)?([a-z0-9_\/\-]+)\.([a-z0-9_\-]+)\.([a-z0-9_\-]+)$/g;
    const isValid = eId && eId.match(validEID) && eId.length <= 500;
    if (!isValid) {
      error('Invalid e_ID naming syntax for', eId, 'See', 'https://confluence.godaddy.com/display/CKPT/Event+Naming+and+Formats');
    }
    return !!isValid;
  }

  _validateEIDCharacters(eId) {
    const invalidCharacters = /[^a-z0-9\_\/\.\-]/g;
    const hasInvalidCharacters = eId && eId.match(invalidCharacters);
    if (hasInvalidCharacters) {
      error(`Invalid characters in e_ID: ${eId} The event has been DROPPED. See https://confluence.godaddy.com/display/CKPT/Event+Naming+and+Formats`);
    }

    return !hasInvalidCharacters;
  }

  _buildEvent() {

    setClientSessionIdentifiers(page);

    // An identifier used to tie together page request and page events. This value changes with every page request.
    this.pageEvent.set('corrid', page.get('corrid'));

    // assemble query string properties.
    this.pageEvent.set('event_type', 'page.event');
    this.pageEvent.set('eventdate', getISODate());
    // experiment, click, impression, etc.
    this.pageEvent.set('eventtype', this.data.ALL.type);

    // We will only accept the dom_element and dom_event in the v1 schema
    this.pageEvent.merge(getBrowserClickEventData(this.data.ALL.dom_element, this.data.ALL.dom_event));

    // in the v2 schema, the click event data is supplied as a property
    this.pageEvent.merge({
      href: this.data.ALL.href,
      tcode: this.data.ALL.tcode,
      tms: this.data.ALL.tms,
      ci: this.data.ALL.ci
    });

    // override dom element attributes with those passed in.
    if (this.data.ALL.eid) {
      this.pageEvent.set('e_id', this.data.ALL.eid);
    }

    // Put both tdata inputs (string from html vs list of lists from cmdLogPageEvent param)
    // into a common structore (object/property bag)
    this.tData.setTDataString(this.pageEvent.get('usrin'));
    this.tData.merge(this.data.ALL.properties);
    const tDataString = this.tData.stringify();

    // Set the tData string that will be sent to Traffic
    this.pageEvent.set('usrin', tDataString);

    // Get event_label
    // Use event_label provided from schema (if provided) before one provided in tdata (if provided)
    const eventLabel = this.data.ALL.event_label || this.tData.getProperties().event_label;
    if (eventLabel) {
      this.pageEvent.set('event_label', eventLabel);
    }

    // Merge datalayer extras, which includes an identifier to
    // correlate the hit between downstream systems (hit_id)
    this.pageEvent.merge(this.extras);

    addDefaultEventProperties(this.pageEvent);
  }
}

export default AddEventHandler;
