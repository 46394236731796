import { setCookie } from '@exp/exp-utils/helper/cookie';

import cmdLogPageRequest from '../../../tcc/src/traffic/methods/cmdLogPageRequest';
import cmdLogPageEvent from '../../../tcc/src/traffic/methods/cmdLogPageEvent';
import cmdLog from '../../../tcc/src/traffic/methods/cmdLog';
import cmdGetTrackingValues from '../../../tcc/src/traffic/methods/cmdGetTrackingValues';
import cmdLogPagePerf from '../../../tcc/src/traffic/methods/cmdLogPagePerf';
import cmdLogImpression from '../../../tcc/src/traffic/methods/cmdLogImpression';

// Do init for the module
setCookie('traffic', '', 20, '/');

// Methods used for the traffic data layer process are published through here
export { cmdLogPageRequest, cmdLogPageEvent, cmdLog, cmdGetTrackingValues, cmdLogPagePerf, cmdLogImpression };
