import SchemaHandler from '@exp/exp-utils/schema/schemaHandler';
import { merge as mergeObject } from '@exp/exp-utils/helper/object';

import trackingValues from '@tcc/shared/src/traffic/trackingValues';

import experimentTracker from '../experimentTracking';

const eidPrefix = 'traffic.tcc.instrumentation';

class AddExperimentAssignmentHandler extends SchemaHandler { // eslint-disable-line id-length

  preProcess() {}

  process() {
    super.process({
      ALL: (input) => { this._handle(input); }
    });
  }

  _handle(input) {
    // call the 'add_event' schema
    this.schemaHelper.handleSchema('add_event', undefined, 'v1', {
      type: 'experiment',
      eid: `${eidPrefix}.experiment.add_experiment`,
      properties: mergeObject({ experiment_type: this.schemaType }, input)
    },
    this.extras,
    // this schema's sinks will override add_event's sinks
    this.sinks);

    experimentTracker.set(input.experiment_id, input.variant_id);
    trackingValues.set('experiments', experimentTracker.getProperties());
  }
}

export default AddExperimentAssignmentHandler;
