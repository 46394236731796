import { merge as objectMerge } from '@exp/exp-utils/helper/object';
import { getWindow as getBrowserWindow } from '@exp/exp-utils/helper/browser';

import SchemaHandler from '@exp/exp-utils/schema/schemaHandler';

class AddVirtualPagePerfHandler extends SchemaHandler {
  process() {
    super.process({
      ALL: () => { this._handle(); }
    });
  }

  _handle() {
    // call the 'add_perf' schema
    this.schemaHelper.handleSchema('add_perf', undefined, 'v1', {
      type: 'pageperf',
      properties: objectMerge(this.data.ALL.timing_metrics, {
        nav_type: 'soft',
        page: this.data.ALL.virtual_path })
    },
    this.extras,
    // this schema's sinks will override add_perf's sinks
    this.sinks);

    // Set a performance mark if name is provided and an error did not occur while processing
    // the virtual perf event.
    const w = getBrowserWindow();
    if (this.data.ALL.perf_mark_name && w.performance && w.performance.mark) {
      w.performance.mark(this.data.ALL.perf_mark_name);
    }
  }
}

export default AddVirtualPagePerfHandler;
