import { getISODate } from '@exp/exp-utils/helper/date';
import {
  EventPropertySet,
  getEventProperties,
  addDefaultEventProperties,
  setClientSessionIdentifiers } from '../../../../shared/src/traffic/eventProperties';
import SchemaHandler from '@exp/exp-utils/schema/schemaHandler';

import { sendEvent } from '../../../../shared/src/traffic/eventSend';

import page from '../../../../shared/src/traffic/eventPageProperties';

class AddPerfHandler extends SchemaHandler {

  process() {
    super.process({
      EVENT_SVC: () => { this._handleEventSvc(); }
    });
  }

  _handleEventSvc() {
    sendEvent(this.pageEvent.getProperties(), '/b.aspx', 'GET');
  }

  preProcess() {
    setClientSessionIdentifiers(page);

    this.pageEvent = new EventPropertySet();

    // An identifier used to tie together page request and page events. This value changes with every page request.
    this.pageEvent.set('corrid', page.get('corrid'));

    // assemble query string properties.
    this.pageEvent.set('event_type', 'page.log');
    this.pageEvent.set('eventdate', getISODate());

    // experiment, click, impression, etc.
    this.pageEvent.set('eventtype', this.data.ALL.type);

    // Hard vs Soft navigation (normal page request vs virtual page request)
    this.pageEvent.set('nav_type', this.data.ALL.nav_type);

    if (typeof this.data.ALL.properties === 'object') {
      this.pageEvent.merge(this.data.ALL.properties);
    }

    // Merge datalayer extras, which includes an identifier to
    // correlate the hit between downstream systems (hit_id)
    this.pageEvent.merge(this.extras);

    // merge properties from the datalayer (ignoring gtm. and tcc.)
    this.pageEvent.merge(getEventProperties());
    addDefaultEventProperties(this.pageEvent);
  }
}

export default AddPerfHandler;
