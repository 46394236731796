import { PropertyDefinitionBlock, ObjectDefinitionBlock, CollectionDefinitionBlock } from '@exp/exp-utils/schema/schemaDefinitionBlocks';

import * as schemaTransforms from '@tcc/shared/src/experiments/experimentSchemaTransforms';

// Handlers
import AddPagePerfHandler from '@tcc/shared/src/experiments/handlers/addPagePerf';
import AddPerfHandler from '@tcc/shared/src/experiments/handlers/addPerf';
import AddEventHandlerTcc from './handlers/addEventTcc';
import AddExperimentAssignment from './handlers/addExperimentAssignment';
import AddPromotion from './handlers/addPromotion';
import AddEcommEvent from './handlers/addEcommEvent';
import AddPageRequestTcc from './handlers/addPageRequestTcc';
import AddImpression from './handlers/addImpression';
import AddVirtualPagePerf from './handlers/addVirtualPagePerf';
import GetTrackingValues from './handlers/getTrackingValues';
import AddGenericConversion from './handlers/addGenericConversion';
import GetVariantForExperiment from './handlers/getVariantForExperiment';

/*
For documentation on how to use the schema framework, refer to
https://github.secureserver.net/Experimentation/exp-utils/blob/master/test/schema/example/exampleSchema.js
*/

/*      Shared Object Schemas      */

const _experimentAssignmentObj = () => {
  return [
    new PropertyDefinitionBlock('experiment_id').required(),
    new PropertyDefinitionBlock('variant_id').required(),
    new PropertyDefinitionBlock('content_id').optional(),
    new PropertyDefinitionBlock('experiment_source').optional()
  ];
};

const _eventObjV2 = () => {
  return [
    new PropertyDefinitionBlock('eid').required(),
    new PropertyDefinitionBlock('href').optional(),
    new PropertyDefinitionBlock('tcode').optional(),
    new PropertyDefinitionBlock('tms').optional(),
    new PropertyDefinitionBlock('ci').optional(),
    new PropertyDefinitionBlock('properties').optional()
  ];
};

const _eventObjV1 = () => {
  return [
    new PropertyDefinitionBlock('eid').optional(),
    new PropertyDefinitionBlock('properties').optional(),
    new PropertyDefinitionBlock('dom_element').optional(),
    new PropertyDefinitionBlock('dom_event').optional()
  ];
};

const _promotionObj = (eventObj) => {
  return [
    new PropertyDefinitionBlock('id')
      .required()
      .sinks(['GA']),
    new PropertyDefinitionBlock('name')
      .optional()
      .sinks(['GA']),
    new PropertyDefinitionBlock('creative_name')
      .optional()
      .sinks(['GA']),
    new PropertyDefinitionBlock('creative_slot')
      .optional()
      .sinks(['GA']),
    new ObjectDefinitionBlock()
      .substitute(eventObj)
      .sinks(['EVENT_SVC'])
  ];
};

const _eidObj = () => {
  return [
    new PropertyDefinitionBlock('eid').required(),
    new PropertyDefinitionBlock('eid_label').optional()
  ];
};

// prdct supports v1 purchase
// TODO Brandon 11/16/2018 Remove after Cart moves to V2 Purchase
// https://jira.godaddy.com/browse/EXP-1107
const _productObj = () => {
  return [
    new PropertyDefinitionBlock('id')
      .required()
      .transformKeys({ TEALIUM: 'product_id' }),
    new PropertyDefinitionBlock('quantity')
      .required()
      .transformKeys({ TEALIUM: 'product_quantity' }),
    new PropertyDefinitionBlock('name')
      .optional()
      .sinks(['GA']),
    new PropertyDefinitionBlock('brand')
      .optional()
      .sinks(['GA']),
    new PropertyDefinitionBlock('variant')
      .optional()
      .sinks(['GA']),
    new PropertyDefinitionBlock('coupon')
      .optional()
      .sinks(['GA'])
  ];
};

// Support Purchase V1
// TODO Brandon 11/16/2018 Remove after Cart moves to V2 Purchase
// https://jira.godaddy.com/browse/EXP-1107
const _cartProductObj = () => {
  return [
    new PropertyDefinitionBlock('currency')
      .required()
      .transformKeys({ TEALIUM: 'order_currency' }),
    new PropertyDefinitionBlock('value')
      .required()
      .transformKeys({ TEALIUM: 'order_total_usd' }),
    new PropertyDefinitionBlock('coupon')
      .optional()
      .transformKeys({ TEALIUM: 'source_code' }),

    // In addition to the fields defined by the 'product' map, the
    // objects in this collection will also require the price field.
    new CollectionDefinitionBlock().map('items', _productObj())
      .extend([
        // This is an example of the 'product' object being extended
        // to require additional properties
        new PropertyDefinitionBlock('price')
          .required()
          .transformKeys({ TEALIUM: 'product_price_usd' }),
        new PropertyDefinitionBlock('full_product_name')
          .optional()
          .sinks(['TEALIUM'])
          .transformKeys({ TEALIUM: 'product_name' }),
        new PropertyDefinitionBlock('cj_product_id')
          .optional()
          .sinks(['TEALIUM']),
        new PropertyDefinitionBlock('cj_product_price_usd')
          .optional()
          .sinks(['TEALIUM']),
        new PropertyDefinitionBlock('cj_product_quantity')
          .optional()
          .sinks(['TEALIUM']),
        new PropertyDefinitionBlock('item_tracking_code')
          .optional()
          .transformKeys({ GA: 'item_tracking_code_product' }),
        new PropertyDefinitionBlock('product_category_id')
          .optional()
          .sinks(['TEALIUM'])
          .transformKeys({ TEALIUM: 'product_category' }),
        new PropertyDefinitionBlock('category')
          .optional()
          .sinks(['GA'])
      ])
  ];
};

// Supports Purchase V1
// TODO Brandon 11/16/2018 Remove after Cart moves to V2 Purchase
// https://jira.godaddy.com/browse/EXP-1107
const _gaPurchaseObj = () => {
  return [
    new PropertyDefinitionBlock('transaction_id')
      .required()
      .transformKeys({ TEALIUM: 'order_id' }),
    new PropertyDefinitionBlock('activation_redirect')
      .optional(),
    new PropertyDefinitionBlock('first_order')
      .optional(),
    new PropertyDefinitionBlock('new_customer')
      .optional(),
    new PropertyDefinitionBlock('order_discount_usd')
      .optional()
      .transformKeys({ TEALIUM: 'order_discount' }),
    new PropertyDefinitionBlock('order_total_new_usd')
      .optional(),
    new PropertyDefinitionBlock('order_total_renewal_usd')
      .optional(),
    new PropertyDefinitionBlock('order_from_website')
      .optional()
      .sinks(['TEALIUM']),
    new PropertyDefinitionBlock('page_type')
      .optional()
      .sinks(['TEALIUM']),
    new PropertyDefinitionBlock('payment_pending')
      .optional(),
    new PropertyDefinitionBlock('payment_processor')
      .optional(),
    new ObjectDefinitionBlock()
      .substitute(_cartProductObj())
      .transform({
        TEALIUM: [schemaTransforms.transformForTealium]
      })
  ];
};

const _checkoutProductObj = () => {
  return [
    // Extend the cart_prdct object with extra properties
    new ObjectDefinitionBlock().substitute(_cartProductObj()).extend([
      new PropertyDefinitionBlock('checkout_step').required(),
      new PropertyDefinitionBlock('checkout_option').required(),
      new ObjectDefinitionBlock()
        .substitute(_eidObj())
        .sinks(['GA'])
    ])
  ];
};

// supports v1 add_to_cart, v1 product_impression and v2 purchase
const _baseProductObj = () => {
  return [
    new PropertyDefinitionBlock('id')
      .required()
      .transformKeys({ TEALIUM: 'product_id' }),
    new PropertyDefinitionBlock('qt')
      .required()
      .transformKeys({ GA: 'quantity', TEALIUM: 'product_quantity' }),
    new PropertyDefinitionBlock('ca')
      .optional()
      .transformKeys({ GA: 'category', TEALIUM: 'product_category_name' }),
    new PropertyDefinitionBlock('br')
      .optional()
      .sinks(['GA'])
      .transformKeys({ GA: 'brand' }),
    new PropertyDefinitionBlock('va')
      .optional()
      .sinks(['GA'])
      .transformKeys({ GA: 'variant' }),
    new PropertyDefinitionBlock('cc')
      .optional()
      .sinks(['GA'])
      .transformKeys({ GA: 'coupon' })
  ];
};

// V1 Product Impression and Add To Cart Product Map
const _ecommProductObj = () => {
  return [
    new CollectionDefinitionBlock().map('items', _baseProductObj()).extend([
      new PropertyDefinitionBlock('pr')
        .optional()
        .transformKeys({ GA: 'price', TEALIUM: 'product_price_usd' })
    ])
  ];
};

// V2 Purchase Product Map
const _purchaseProductObj = () => {
  return [
    new CollectionDefinitionBlock().map('items', _baseProductObj()).extend([
      new PropertyDefinitionBlock('pr')
        .required()
        .transformKeys({ GA: 'price', TEALIUM: 'product_price_usd' }),
      new PropertyDefinitionBlock('prcaid')
        .optional()
        .sinks(['TEALIUM'])
        .transformKeys({ TEALIUM: 'product_category' }),
      new PropertyDefinitionBlock('cj')
        .optional()
        .sinks(['TEALIUM']),
      new PropertyDefinitionBlock('pritc')
        .optional()
        .transformKeys({ GA: 'item_tracking_code_product', TEALIUM: 'item_tracking_code' })
    ])
  ];
};

// Supports v1 add_to_cart, v1 product_impression and v2 purchase
const _packageObj = () => {
  return [
    new PropertyDefinitionBlock('pkgid')
      .optional()
      .transformKeys({ ALL: 'package_id' }),
    new PropertyDefinitionBlock('pkgpr')
      .optional()
      .transformKeys({ TEALIUM: 'package_price_usd' }),
    new PropertyDefinitionBlock('pkgca')
      .optional()
      .transformKeys({ TEALIUM: 'package_category' }),
    new PropertyDefinitionBlock('pkgqt')
      .optional()
      .transformKeys({ TEALIUM: 'package_quantity' })
  ];
};

// V1 Product Impression and Add To Cart Product map
const _ecommPackageObj = () => {
  return [
    new CollectionDefinitionBlock().map('pkgs', _packageObj())
  ];
};

// V2 Purchase data map
const _purchaseObj = () => {
  return [
    new PropertyDefinitionBlock('cu')
      .required()
      .transformKeys({ GA: 'currency', TEALIUM: 'order_currency' }),
    new PropertyDefinitionBlock('ti')
      .required()
      .transformKeys({ GA: 'transaction_id', TEALIUM: 'order_id' }),
    new PropertyDefinitionBlock('tr')
      .required()
      .transformKeys({ EVENT_SVC: 'value', GA: 'value', TEALIUM: 'order_total_usd' }),
    new PropertyDefinitionBlock('tcc')
      .optional()
      .transformKeys({ GA: 'coupon', TEALIUM: 'source_code' }),
    new PropertyDefinitionBlock('fo')
      .optional()
      .transformKeys({ ALL: 'first_order' }),
    new PropertyDefinitionBlock('nc')
      .optional()
      .transformKeys({ ALL: 'new_customer' }),
    new PropertyDefinitionBlock('tdr')
      .optional()
      .transformKeys({ ALL: 'order_discount_usd' }),
    new PropertyDefinitionBlock('tr_new')
      .optional()
      .transformKeys({ ALL: 'order_total_new_usd' }),
    new PropertyDefinitionBlock('tr_renew')
      .optional()
      .transformKeys({ ALL: 'order_total_renewal_usd' }),
    new PropertyDefinitionBlock('pp')
      .optional()
      .transformKeys({ ALL: 'payment_pending' }),
    new PropertyDefinitionBlock('psrc')
      .optional()
      .sinks(['GA'])
      .transformKeys({ GA: 'payment_processor' }),
    new ObjectDefinitionBlock()
      .substitute(_ecommPackageObj())
      .transform({
        TEALIUM: [schemaTransforms.transformForTealiumV2]
      }),
    new ObjectDefinitionBlock()
      .substitute(_purchaseProductObj())
      .transform({
        TEALIUM: [schemaTransforms.transformForTealiumV2]
      })
  ];
};

const navTimingObj = () => {
  return [
    new PropertyDefinitionBlock('navigationStart').required(),
    new PropertyDefinitionBlock('fetchStart').optional(),
    new PropertyDefinitionBlock('domainLookupStart').optional(),
    new PropertyDefinitionBlock('domainLookupEnd').optional(),
    new PropertyDefinitionBlock('connectStart').optional(),
    new PropertyDefinitionBlock('connectEnd').optional(),
    new PropertyDefinitionBlock('requestStart').optional(),
    new PropertyDefinitionBlock('responseStart').optional(),
    new PropertyDefinitionBlock('responseEnd').optional(),
    new PropertyDefinitionBlock('domLoading').optional(),
    new PropertyDefinitionBlock('domInteractive').optional(),
    new PropertyDefinitionBlock('domContentLoaded').optional(),
    new PropertyDefinitionBlock('domComplete').optional(),
    new PropertyDefinitionBlock('loadEventStart').required(),
    new PropertyDefinitionBlock('loadEventEnd').optional(),
    new PropertyDefinitionBlock('transferSize').optional(),
    new PropertyDefinitionBlock('encodedBodySize').optional(),
    new PropertyDefinitionBlock('decodedBodySize').optional()
  ];
};

const genericConversionObj = () => {
  return [
    new ObjectDefinitionBlock().map('properties', [
      new PropertyDefinitionBlock('virtual_order_id')
        .optional().
        transformKeys({ TEALIUM: 'order_id' }),
      new PropertyDefinitionBlock('app_name')
        .optional(),
      new PropertyDefinitionBlock('package_id')
        .optional(),
      new PropertyDefinitionBlock('package_category')
        .optional()
    ]),
    new PropertyDefinitionBlock('area')
      .required(),
    new PropertyDefinitionBlock('product')
      .required(),
    new PropertyDefinitionBlock('revenue')
      .required(),
    new PropertyDefinitionBlock('action')
      .required()
  ];
};

const _getVariantForExpObj = () => {
  return [
    new PropertyDefinitionBlock('experiment_id').required(),
    new PropertyDefinitionBlock('callback').required(),
    new PropertyDefinitionBlock('attributes').optional()
  ];
};

const _experimentAssignmentSchema = () => {
  return {
    // Becauses this schema handler calls the add_event handler,
    // the sink defined here will override the add_event schema sink
    sinks: ['GA', 'EVENT_SVC'],
    handler: AddExperimentAssignment,
    data: [
      new ObjectDefinitionBlock()
        .substitute(_experimentAssignmentObj())
    ]
  };
};

const _promoClickSchema = (eventObj) => {
  return {
    handler: AddPromotion,
    sinks: ['GA', 'EVENT_SVC'],
    data: [
      new ObjectDefinitionBlock()
        .substitute(_promotionObj(eventObj))
        .transform({ GA: [(input) => {
          return { promotions: [input] };
        }]
        })
    ]
  };
};

const _promoImpressionSchema = (eventObj) => {
  return {
    handler: AddPromotion,
    sinks: ['GA', 'EVENT_SVC'],
    data: [
      new CollectionDefinitionBlock()
        .map('impressions', _promotionObj(eventObj))
        .transformKeys({ ALL: 'promotions' })
    ]
  };
};

const _addEventSchema = (eventObj) => {
  return {
    handler: AddEventHandlerTcc,
    sinks: ['GA', 'EVENT_SVC'],
    data: [
      new PropertyDefinitionBlock('type')
        .required()
        .transform({ ALL: [schemaTransforms.filterLogType] }),
      new ObjectDefinitionBlock()
        .substitute(eventObj)
        .transform({ ALL: [schemaTransforms.validateEid] }),
      new PropertyDefinitionBlock('event_label').optional()
    ]
  };
};

const _addImpressionSchema = (eventObj) => {
  return {
    handler: AddImpression,
    sinks: ['GA', 'EVENT_SVC'],
    data: [
      new ObjectDefinitionBlock()
        .substitute(eventObj)
        .transform({ ALL: [schemaTransforms.validateEid] })
    ]
  };
};

/*      Command Schemas     */

const commandSchemaDefinitions = {
  add_event: {
    v1: _addEventSchema(_eventObjV1()),
    v2: _addEventSchema(_eventObjV2())
  },
  add_generic_conversion: {
    v1: {
      handler: AddGenericConversion,
      sinks: ['GA', 'EVENT_SVC', 'TEALIUM'],
      data: [
        new ObjectDefinitionBlock()
          .substitute(genericConversionObj())
          .transform({
            ALL: [schemaTransforms.applyGenericConversionEid],
            TEALIUM: [schemaTransforms.unnestProperties]
          })
      ]
    }
  },
  add_impression: {
    v1: _addImpressionSchema(_eventObjV1()),
    v2: _addImpressionSchema(_eventObjV2())
  },
  add_page_request: {
    v1: {
      handler: AddPageRequestTcc,
      sinks: ['GA', 'EVENT_SVC'],
      data: [
        new PropertyDefinitionBlock('virtual_path').optional()
      ]
    }
  },
  add_virtual_page_perf: {
    v1: {
      handler: AddVirtualPagePerf,
      sinks: ['EVENT_SVC'],
      data: [
        new PropertyDefinitionBlock('virtual_path').required(),
        new ObjectDefinitionBlock().map('timing_metrics', navTimingObj()),
        new PropertyDefinitionBlock('perf_mark_name').optional()
      ]
    }
  },
  add_page_perf: {
    v1: {
      handler: AddPagePerfHandler,

      // add_page_perf still depends on add_perf in a legacy way.
      // If you need to control where the output for this schema will go,
      // adjust the 'add_perf' output group
      sinks: ['EVENT_SVC']
    }
  },
  // Ad-hoc perf interface.
  // add_page_perf reuses this schema to log perf data
  // Helper libraries / consumers use this to log virtual page requests
  add_perf: {
    v1: {
      handler: AddPerfHandler,
      sinks: ['EVENT_SVC'],
      data: [
        new PropertyDefinitionBlock('type')
          .required()
          .transform({ ALL: [schemaTransforms.filterLogType] }),
        new PropertyDefinitionBlock('properties')
          .optional()
      ]
    }
  },
  get_tracking_values: {
    v1: {
      handler: GetTrackingValues,
      data: [
        new PropertyDefinitionBlock('callback').required()
      ]
    }
  },
  get_variant_for_experiment: {
    v1: {
      handler: GetVariantForExperiment,
      data: _getVariantForExpObj()
    },
    v2: {
      handler: GetVariantForExperiment,
      data: [
        new ObjectDefinitionBlock()
          .substitute(_getVariantForExpObj()).extend([
            new PropertyDefinitionBlock('traffic_type').required(),
            new PropertyDefinitionBlock('configuration').optional()
          ])
      ]
    }
  },
  // DEPRECATED. Use add_experiment_assignment
  add_experiment: {
    v1: {
      handler: AddExperimentAssignment,
      sinks: ['GA', 'EVENT_SVC'],
      data: [
        new ObjectDefinitionBlock()
          .substitute(_experimentAssignmentObj()).extend([
            new PropertyDefinitionBlock('experiment_type').optional()
          ])
      ]
    }
  },
  add_experiment_assignment: [
    {
      type: 'abn',
      v1: _experimentAssignmentSchema()
    },
    {
      type: 'mvt',
      v1: _experimentAssignmentSchema()
    }
  ],
  add_promotion: [
    {
      type: 'click',
      v1: _promoClickSchema(_eventObjV1()),
      v2: _promoClickSchema(_eventObjV2())
    },
    {
      type: 'impression',
      v1: _promoImpressionSchema(_eventObjV1()),
      v2: _promoImpressionSchema(_eventObjV2())
    }
  ],
  add_ecomm_event: [
    {
      type: 'product_impression',
      v1: {
        handler: AddEcommEvent,
        sinks: ['TEALIUM'],
        data: [
          new PropertyDefinitionBlock('el')
            .optional()
            .sinks(['GA'])
            .transformKeys({ GA: 'eid_label' }),
          new ObjectDefinitionBlock().substitute(_ecommProductObj())
            .transform({
              GA: [schemaTransforms.applyProductImpressionEid], TEALIUM: [schemaTransforms.transformForTealiumV2]
            }),
          new ObjectDefinitionBlock().substitute(_ecommPackageObj())
            .transform({
              GA: [schemaTransforms.getPackagesForGA], TEALIUM: [schemaTransforms.transformForTealiumV2]
            })
        ]
      }
    },
    {
      type: 'add_to_cart',
      v1: {
        handler: AddEcommEvent,
        sinks: ['GA', 'TEALIUM'],
        data: [
          new PropertyDefinitionBlock('el')
            .optional()
            .sinks(['GA'])
            .transformKeys({ GA: 'eid_label' }),
          new ObjectDefinitionBlock().substitute(_ecommProductObj())
            .transform({
              GA: [schemaTransforms.applyAddToCartEid],
              TEALIUM: [schemaTransforms.transformForTealiumV2]
            }),
          new ObjectDefinitionBlock().substitute(_ecommPackageObj())
            .transform({
              GA: [schemaTransforms.getPackagesForGA],
              TEALIUM: [schemaTransforms.transformForTealiumV2]
            })
        ]
      }
    },
    {
      type: 'remove_from_cart',
      v1: {
        handler: AddEcommEvent,
        sinks: ['GA'],
        data: [
          new ObjectDefinitionBlock().substitute(_eidObj()),
          new PropertyDefinitionBlock('currency').optional(),
          new PropertyDefinitionBlock('value').optional(),
          new CollectionDefinitionBlock().map('items', _productObj()).extend([
            new PropertyDefinitionBlock('price').required()
          ])
        ]
      }
    },
    {
      type: 'begin_checkout',
      v1: {
        handler: AddEcommEvent,
        sinks: ['GA'],
        data: [
          new ObjectDefinitionBlock().substitute(_checkoutProductObj())
        ]
      }
    },
    {
      type: 'checkout_progress',
      v1: {
        handler: AddEcommEvent,
        sinks: ['GA'],
        data: [
          new ObjectDefinitionBlock().substitute(_checkoutProductObj())
        ]
      }
    },
    {
      type: 'purchase',
      v1: {
        handler: AddEcommEvent,
        sinks: ['GA', 'TEALIUM', 'EVENT_SVC'],
        data: [
          new ObjectDefinitionBlock()
            .substitute(_gaPurchaseObj())
            .transform({
              GA: [
                schemaTransforms.applyPurchaseEventEid,
                schemaTransforms.removeUnwantedGaPurchaseProps
              ],
              EVENT_SVC: [schemaTransforms.applyPurchaseEventEid],
              TEALIUM: [schemaTransforms.emptyStringForUndefined]
            })
        ]
      },
      v2: {
        handler: AddEcommEvent,
        sinks: ['GA', 'TEALIUM', 'EVENT_SVC'],
        data: [
          new ObjectDefinitionBlock()
            .substitute(_purchaseObj())
            .transform({
              GA: [
                schemaTransforms.applyPurchaseEventEid,
                schemaTransforms.getPackagesForGA,
                schemaTransforms.removeUnwantedGaPurchaseProps
              ],
              EVENT_SVC: [schemaTransforms.applyPurchaseEventEid],
              TEALIUM: [schemaTransforms.emptyStringForUndefined]
            })
        ]
      }
    }]
};

export default commandSchemaDefinitions;
