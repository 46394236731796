import SchemaHandler from '@exp/exp-utils/schema/schemaHandler';

import { getPerformanceData } from '../../../../shared/src/performance/performance';

class AddPagePerfHandler extends SchemaHandler {
  process() {
    super.process({
      EVENT_SVC: () => { this._handleEventSvc(); }
    });
  }

  _handleEventSvc() {
    getPerformanceData('manual');
  }
}

export default AddPagePerfHandler;
