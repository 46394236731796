//
// tData Object has the format [[key1, value1], [key2, value2]]
// tData String has the format key1,value1^key2,value2
//

import { error } from '@exp/exp-utils/helper/logger';
import { isArray } from '@exp/exp-utils/helper/array';
import { parseValue, parseString, cleanKeyNames, stringify } from '@exp/exp-utils/helper/object';
import PropertySet from '@exp/exp-utils/helper/property';

const getObjFromTDataList = (tData) => {
  const result = {};
  if (isArray(tData)) {
    for (let i = 0; i < tData.length; i++) {
      const kvPair = tData[i];
      if (isArray(kvPair)) {
        result[kvPair[0]] = parseValue(kvPair);
      }
    }
  }
  return result;
};

const getObjFromTDataString = (tDataString) => {
  return parseString(tDataString, '^', ',');
};

class TData extends PropertySet {

  setTDataString(tDataString) {
    super.merge(getObjFromTDataString(tDataString));
  }

  stringify() {
    const properties = this.getProperties();
    cleanKeyNames(properties, '.', '_');

    const result = stringify(properties, '^', ',');

    if (result) {
      return result;
    }
  }

  set(name, value) {
    if (typeof value !== 'object' && {}.toString.call(value) !== '[object Function]') {
      super.set(name, value);
    } else {
      error(`Invalid key/value pair found in Traffic's custom properties (tData). Objects, arrays, and functions are not supported.\n\nKey: '${name}' Value: '${value}' Type: '${typeof value}'.`);
    }
  }
}

export default TData;
export {
  getObjFromTDataList,
  getObjFromTDataString
};
