import { debug } from '@exp/exp-utils/helper/logger';

import { getWindow, getDocument, formatPageName } from '@exp/exp-utils/helper/browser';
import { getCookieValues, setCookie } from '@exp/exp-utils/helper/cookie';
import { getCleanQueryString } from '@exp/exp-utils/helper/url';
import { merge as objectMerge } from '@exp/exp-utils/helper/object';
import SchemaHandler from '@exp/exp-utils/schema/schemaHandler';

import { sendEvent } from '../../../../shared/src/traffic/eventSend';
import page from '../../../../shared/src/traffic/eventPageProperties';

import {
  getEventProperties,
  setClientSessionIdentifiers,
  addDefaultEventProperties } from '../../../../shared/src/traffic/eventProperties';

let lastPathName;

class AddPageRequestHandler extends SchemaHandler {
  process(sinkFnMap) {
    super.process(objectMerge({
      EVENT_SVC: () => { this._handleEventSvc(); }
    }, sinkFnMap));

  }

  _handleEventSvc() {
    sendEvent(page.getProperties(), '/image.aspx', 'GET');
  }
  _getViewProperties() {
    const v = {};
    const win = getWindow();
    const doc = getDocument();

    v.browx = win.innerWidth ? win.innerWidth : doc.body.offsetWidth;
    v.browy = win.innerHeight ? win.innerHeight : doc.body.offsetHeight;
    v.resx = win.screen.width;
    v.resy = win.screen.height;
    v.cdepth = win.screen.colorDepth;

    return v;
  }

  preProcess() {
    let pathname = this.data.ALL.virtual_path;
    // merge properties from the datalayer (ignoring gtm. and tcc.)
    page.merge(getEventProperties());

    setClientSessionIdentifiers(page);

    page.set('event_type', 'page.request');

    if (typeof (pathname) === 'undefined') {
      // Send just the pathname to Traffic unless a virtual page was specified
      pathname = getWindow().location.pathname;
    } else {
      page.set('virtual_path', pathname);
    }

    if (process.env.NODE_ENV !== 'production') {
      debug('cmd: LogPageRequest invoked with path', pathname);
    }

    if (lastPathName === pathname) {
      throw 'cmd: LogPageRequest duplicate page request detected, ignoring duplicate path';
    }
    lastPathName = pathname;

    page.set('page', formatPageName(pathname));
    page.set('hw', getWindow().history.length);

    // merge properties from browser view stats
    page.merge(this._getViewProperties());

    // get query string from URL
    const qs = getCleanQueryString();
    if (qs) {
      page.set('querystring', qs);
    }

    page.merge(getCookieValues('tcc_refer'));
    setCookie('tcc_refer', '', -1, '/');

    // Merge datalayer extras, which includes an identifier to
    // correlate the hit between downstream systems (hit_id)
    page.merge(this.extras);

    addDefaultEventProperties(page);
  }
}

export default AddPageRequestHandler;
