import { merge as objectMerge } from '@exp/exp-utils/helper/object';

import SchemaHandler from '@exp/exp-utils/schema/schemaHandler';

import { sendEcommerceEvent as sendGaEcommerceEvent } from '../../ga/ga';
import { sendEcommerceEvent as sendTealiumEcommerceEvent } from '../../tealium/tealium';

let _isDuplicatePurchase = false;

class AddEcommEventHandler extends SchemaHandler {
  preProcess() {
    if (this.schemaType === 'purchase') {
      if (_isDuplicatePurchase) {
        throw 'cmd: LogEcommEvent duplicate purchase event detected, ignoring duplicate';
      }
      _isDuplicatePurchase = true;
    }
  }

  process() {
    super.process({
      TEALIUM: (input) => { this._handleTealium(input); },
      GA: (input) => { this._handleGA(input); },
      EVENT_SVC: (input) => { this._handleEventSvc(input); }
    });
  }

  _handleTealium(input) {
    sendTealiumEcommerceEvent(this.schemaType, input);
  }

  _handleGA(input) {
    sendGaEcommerceEvent(this.schemaType, objectMerge(this.extras, input));
  }

  _handleEventSvc(input) {
    if (this.schemaType === 'purchase') {
      // call the 'add_event' schema
      this.schemaHelper.handleSchema('add_event', undefined, 'v1', {
        type: 'purchase',
        eid: input.eid,
        properties: {
          order_total: input.value,
          order_total_renewal_usd: input.order_total_renewal_usd
        }
      },
      this.extras,
      // This call should only go to the event service
      // The current handler will handle calls to GA/Tealium
      ['EVENT_SVC']);
    }
  }
}

const _setDuplicatePurchaseFlag = (value) => {
  _isDuplicatePurchase = value;
};

export default AddEcommEventHandler;

// Private exports for testing
export { _setDuplicatePurchaseFlag };
